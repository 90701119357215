import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

interface ISingleCartProductProps {
  productID: number;
  productName: string;
  productPrice: number;
  productImage: string;
  productAmount: number;
  productColor: string;
  productAvailableNow: boolean;
  handleNewTotalOrderPrice: () => void;
  handleProductRemoved: () => void;
}

const SingleCartProduct = ({
  productID,
  productName,
  productPrice,
  productImage,
  productAmount,
  productColor,
  productAvailableNow,
  handleNewTotalOrderPrice,
  handleProductRemoved,
}: ISingleCartProductProps): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [amount, setAmount] = useState(productAmount);
  const [totalProductPrice, setTotalProductPrice] = useState(
    productPrice * productAmount
  );

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRemoveFromCart = () => {
    const cartProductsString = sessionStorage.getItem("products");
    if (cartProductsString) {
      let cartProducts = JSON.parse(cartProductsString);
      if (cartProducts.length > 1) {
        const indexToRemove = cartProducts.findIndex(
          (cp: any) => cp.id === productID
        );
        if (indexToRemove !== -1) {
          cartProducts.splice(indexToRemove, 1);
        }
        sessionStorage.setItem("products", JSON.stringify(cartProducts));
        setTotalProductPrice(productPrice * amount);
      } else {
        sessionStorage.removeItem("products");
      }
      handleProductRemoved();
    }
  };

  const handleAmountChangeInStorage = (newAmount: number) => {
    const cartProductsString = sessionStorage.getItem("products");
    if (cartProductsString) {
      let cartProducts = JSON.parse(cartProductsString);
      for (let cp of cartProducts) {
        if (cp.id === productID) {
          cp.amount = newAmount;
          break;
        }
      }
      sessionStorage.setItem("products", JSON.stringify(cartProducts));
      handleNewTotalOrderPrice();
    }
  };

  const handlePlus = () => {
    const newAmount = amount + 1;
    setAmount(newAmount);
    setTotalProductPrice(productPrice * newAmount);
    handleAmountChangeInStorage(newAmount);
  };

  const handleMinus = () => {
    if (amount > 1) {
      const newAmount = amount - 1;
      setAmount(newAmount);
      setTotalProductPrice(productPrice * newAmount);
      handleAmountChangeInStorage(newAmount);
    }
  };

  return screenWidth > 785 ? ( // HORIZONTAL
    <TableRow>
      <TableCell>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "15px",
            marginBottom: "15px",
            marginRight: "15px",
          }}
        >
          <Grid
            item
            onClick={() => {
              window.open(
                `https://jewelryshopdoro.com/products/${productID}`,
                "_blank"
              );
            }}
          >
            <img
              style={{
                width: "130px",
                height: "150px",
                marginRight: "8px",
                objectFit: "cover",
              }}
              src={`https://jewelryshopdoro.com/uploads/${productImage}`}
              alt="Korpa Slika"
            ></img>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
              onClick={() => {
                window.open(
                  `https://jewelryshopdoro.com/products/${productID}`,
                  "_blank"
                );
              }}
            >
              {productName}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#79590E", marginRight: "15px" }}
        >{`${productPrice} rsd`}</Typography>
      </TableCell>
      <TableCell>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: "#FFF4E3",
          }}
        >
          <Grid item>
            <IconButton onClick={handleMinus}>
              <Typography>-</Typography>
            </IconButton>
          </Grid>
          <Grid item>
            <Typography>{amount}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handlePlus}>
              <Typography>+</Typography>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#CF9B2E", marginRight: "15px" }}
        >
          {`${totalProductPrice.toFixed(2)} rsd`}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title="Ukloni iz korpe" placement="bottom">
          <IconButton onClick={handleRemoveFromCart}>
            <Close sx={{ fontSize: "20px", color: "#79590E" }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  ) : (
    // VERTICAL
    <TableRow>
      <TableCell>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: screenWidth > 500 ? "row" : "column",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              onClick={() => {
                window.open(
                  `https://jewelryshopdoro.com/products/${productID}`,
                  "_blank"
                );
              }}
            >
              <img
                style={{
                  width: "130px",
                  height: "150px",
                  marginRight: screenWidth > 500 ? "20px" : "0px",
                  objectFit: "cover",
                }}
                src={`https://jewelryshopdoro.com/uploads/${productImage}`}
                alt="Korpa Slika"
              ></img>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: screenWidth > 500 ? "left" : "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: screenWidth > 500 ? "18px" : "24px",
                  color: "#79590E",
                }}
                onClick={() => {
                  window.open(
                    `https://jewelryshopdoro.com/products/${productID}`,
                    "_blank"
                  );
                }}
              >
                {productName}
              </Typography>
            </Grid>
          </Grid>
          <Button onClick={handleRemoveFromCart}>
            <Typography sx={{ fontSize: "14px", color: "#CF9B2E" }}>
              Ukloni iz korpe
            </Typography>
          </Button>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Cena
            </Typography>
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
            >{`${productPrice} rsd`}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Količina
            </Typography>
            <Grid
              item
              sx={{
                display: "flex",
                backgroundColor: "#FFF4E3",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <Grid item>
                <IconButton onClick={handleMinus}>
                  <Typography sx={{ color: "#CF9B2E" }}>-</Typography>
                </IconButton>
              </Grid>
              <Grid item sx={{ marginLeft: "10px", marginRight: "10px" }}>
                <Typography sx={{ color: "#79590E" }}>{amount}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handlePlus}>
                  <Typography sx={{ color: "#CF9B2E" }}>+</Typography>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Ukupno
            </Typography>
            <Typography sx={{ fontSize: "18px", color: "#CF9B2E" }}>
              {`${totalProductPrice.toFixed(2)} rsd`}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const Cart = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0.0);
  const [cartProducts, setCartProducts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Delete all order data from storage if just entered the cart
    sessionStorage.removeItem("customerNote");
    sessionStorage.removeItem("orderInformation");

    // Load the products or leave
    const cartProductsString = sessionStorage.getItem("products");
    if (cartProductsString) {
      const parsedProducts = JSON.parse(cartProductsString);
      setCartProducts(parsedProducts);
    } else {
      navigate("/products");
    }

    // Calculate initial total order price
    handleNewTotalOrderPrice();

    // Handle resize event
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleCustomerNote = (noteText: string) => {
    sessionStorage.setItem("customerNote", noteText);
  };

  const handleProductRemoved = () => {
    const cartProductsString = sessionStorage.getItem("products");
    if (cartProductsString) {
      const parsedProducts = JSON.parse(cartProductsString);
      setCartProducts(parsedProducts);
      handleNewTotalOrderPrice();
    } else {
      navigate("/products");
    }
  };

  const handleNewTotalOrderPrice = () => {
    const newCartProductsString = sessionStorage.getItem("products");
    if (newCartProductsString) {
      const cartProducts = JSON.parse(newCartProductsString);
      let newTotalOrderPrice = 0.0;
      for (let singleCartProduct of cartProducts) {
        newTotalOrderPrice +=
          singleCartProduct.price * singleCartProduct.amount;
      }
      setTotalOrderPrice(newTotalOrderPrice);
    }
  };

  if (cartProducts.length !== 0) {
    return (
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "27px",
          justifyContent: "center",
        }}
      >
        <ScrollToTop />
        <Grid item sx={{ marginTop: "20px" }}>
          <Typography sx={{ fontSize: "14px", color: "#79590E" }}>
            Početna → Korpa
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: "20px", textAlign: "center" }}>
          <Typography sx={{ fontSize: "36px", color: "#79590E" }}>
            Vaša korpa
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: "65px" }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: screenWidth > 785 ? "row" : "column",
              justifyContent: "space-around",
            }}
          >
            <Grid
              item
              sx={{
                marginRight: screenWidth > 1290 ? "120px" : "0px",
              }}
            >
              <TableContainer>
                <Table>
                  {screenWidth > 785 ? (
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography
                            sx={{ fontSize: "20px", color: "#79590E" }}
                          >
                            Proizvod
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{ fontSize: "20px", color: "#79590E" }}
                          >
                            Cena
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{ fontSize: "20px", color: "#79590E" }}
                          >
                            Količina
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{ fontSize: "20px", color: "#79590E" }}
                          >
                            Ukupno
                          </Typography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                  ) : null}
                  <TableBody>
                    {cartProducts.map(
                      (singleCartProduct: any, index: number) => {
                        return (
                          <SingleCartProduct
                            key={singleCartProduct.id}
                            productID={singleCartProduct.id}
                            productName={singleCartProduct.product_name}
                            productPrice={singleCartProduct.price}
                            productImage={singleCartProduct.main_image}
                            productAmount={singleCartProduct.amount}
                            productColor={singleCartProduct.color}
                            productAvailableNow={singleCartProduct.availableNow}
                            handleNewTotalOrderPrice={handleNewTotalOrderPrice}
                            handleProductRemoved={handleProductRemoved}
                          />
                        );
                      }
                    )}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <TextareaAutosize
                          style={{
                            resize: "none",
                            fontSize: "18px",
                            padding: "10px",
                            color: "#CD9C32",
                            border: "1px solid #CD9C32",
                            minHeight: "100px",
                            width: "90%",
                            marginTop: "15px",
                          }}
                          onChange={(event) =>
                            handleCustomerNote(event.target.value)
                          }
                          placeholder="Napomena"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "55px",
                }}
              >
                <Grid item>
                  <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                    {`Ukupno: ${totalOrderPrice.toFixed(2)} rsd`}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginTop: "35px", marginBottom: "20px" }}>
                  <Button
                    sx={{
                      backgroundColor: "#FFF4E3",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                    variant="text"
                    onClick={() => {
                      navigate("/order-info");
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                      Nastavite sa poručivanjem
                    </Typography>
                  </Button>
                </Grid>
                <Grid item sx={{ marginBottom: "20px" }}>
                  <Button onClick={() => navigate("/products")}>
                    <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                      {"< Nastavite kupovinu"}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: "14px", color: "#79590E" }}>
                    Troškovi dostave se obračunacaju prilikom zvršetka kupovine.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return <h1>Korpa je prazna</h1>;
  }
};

export default Cart;
