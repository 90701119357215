import color from "../../../Constants/colors";
import { Grid, Typography } from "@mui/material";
import "../../../Styles/Footer.css";
import useScreenSize from "../../../Hooks/Misc/useScreenSize";
import { Link } from "react-router-dom";

const FooterItem = ({ icon, text }: { icon: string; text: string }) => {
  return (
    <Grid item className="footer-item">
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <img src={icon} alt="ikonica" className="footer-icon" />
        </Grid>
        <Grid item className="footer-text-container">
          <Typography className="footer-text">{text}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
const Footer = () => {
  const phone = useScreenSize("1024");

  return (
    <Grid container direction="column" id="footer">
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{
          width: "100%",
          backgroundColor: `${color.white}`,
        }}
      >
        <Grid
          container
          alignSelf="center"
          direction={phone ? "column-reverse" : "row"}
          alignItems={phone ? "center" : "flex-start"}
          justifyContent="center"
          className="footer-container"
          sx={{ width: "97%", borderTop: "2px solid #CF9B2E" }}
        >
          <Grid item className="left-footer" alignSelf="center">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              spacing={phone ? 10 : 1}
            >
              <Grid item>
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item>
                    <Typography className="footer-title">Konakt</Typography>
                  </Grid>
                  <Link
                    to="https://www.instagram.com/jewelry_shop_doro/?hl=en"
                    target="_blank" // opens the link in a new tab
                    rel="noopener noreferrer" // recommended security attributes
                    style={{ textDecoration: "none" }} // removes text decoration
                  >
                    <FooterItem
                      icon="/instagram.png"
                      text="@jewelry_shop_doro"
                    />
                  </Link>
                  <Link
                    to="https://www.facebook.com/jewelryshopdoro/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <FooterItem icon="/facebook.png" text="Facebook stranica" />
                  </Link>
                  <Link
                    to="viber://chat?number=+381605500750"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <FooterItem icon="/viber.png" text="+381605500750" />
                  </Link>
                  <Link
                    to="mailto:silvershopdoro@gmail.com?subject=Pitanje%20mušterije"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <FooterItem
                      icon="/mail.png"
                      text="silvershopdoro@gmail.com"
                    />
                  </Link>
                </Grid>
              </Grid>
              <Grid item className="contact-footer">
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item>
                    <Typography className="footer-title">Lokacija</Typography>
                  </Grid>
                  <Grid item className="footer-item">
                    <img
                      src="/location.png"
                      alt="location"
                      className="location-icon"
                    />
                  </Grid>
                  <Grid item className="footer-item">
                    <Typography className="footer-text">
                      Laze Telečkog 1
                    </Typography>
                  </Grid>
                  <Grid item className="footer-item">
                    <Typography className="footer-text">Novi Sad</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!phone ? (
                <Grid item>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11234.322788237749!2d19.8465538!3d45.2562711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b119028100867%3A0xc53f068339cb9f89!2sJewelry%20Shop%20Doro!5e0!3m2!1sen!2srs!4v1684667132735!5m2!1sen!2srs"
                    style={{ border: "0" }}
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="google-map"
                    title="Doro map"
                  ></iframe>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item className="right-footer">
            <img
              src="/doro_logo.png"
              alt="logo"
              className={"footer-logo" + (phone ? "-phone" : "")}
            />
          </Grid>
        </Grid>
      </Grid>
      {phone ? (
        <Grid item alignSelf="center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11234.322788237749!2d19.8465538!3d45.2562711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b119028100867%3A0xc53f068339cb9f89!2sJewelry%20Shop%20Doro!5e0!3m2!1sen!2srs!4v1684667132735!5m2!1sen!2srs"
            style={{ border: "0" }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="google-map"
            title="Doro map"
          ></iframe>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Footer;
