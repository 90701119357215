import { useParams } from "react-router-dom";
import { useAllProducts } from "../../../Hooks/useProducts";
import { useSingleProduct } from "../../../Hooks/useProducts";
import useScreenSize from "../../../Hooks/Misc/useScreenSize";
import { ClientSingleProduct as Product } from "../ClientAllProducts/ClientSingleProduct";
import CloseIcon from "@mui/icons-material/Close";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Button,
  Tooltip,
} from "@mui/material";
import ImageSwitcher from "../ImageSwitcher";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import color from "../../../Constants/colors";
import { material } from "../../../Model/Product";
import "../../../Styles/SingleProduct.css";
import { enqueueSnackbar } from "notistack";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import { checkAuth } from "../../../Services/Https/auth";

const translateMaterial = (materialString: material) => {
  switch (materialString) {
    case "gold":
      return "Zlato";
    case "silver":
      return "Srebro";
    default:
      return "/";
  }
};

const ClientSingleProduct = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [amount, setAmount] = useState(1);
  const navigate = useNavigate();
  const phone = useScreenSize();
  const { id } = useParams();
  const { data, refetch } = useSingleProduct(id ?? "");
  const [imageOpen, setImageOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const response = await checkAuth();
        setAuth(response.status === 200);
      } catch (error: unknown) {
        setAuth(false);
      }
    };
    fetchAuth();
  }, []);
  useEffect(() => {
    refetch();
  }, [id, refetch]);

  const handleProductClick = (id: number) => {
    navigate(`/products/${id}`);
  };

  const handleDialogOpen = (newImage: string) => {
    setCurrentImage(newImage);
    setImageOpen(true);
  };

  const [_error, setError] = useState(false);
  const handleError = () => {
    setError(true);
  };

  const renderDialog = () => {
    return (
      <Grid
        sx={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          height: "100vh",
          width: "100%",
          backgroundColor: "#000000CA",
          zIndex: "100",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            width: "70%",
            height: "70%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={
              _error
                ? "/no-image.jpg"
                : `https://jewelryshopdoro.com/uploads/${currentImage}`
            }
            alt="velika slika"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
            onError={handleError}
          />

          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setImageOpen(false);
            }}
            sx={{
              position: "absolute",
              top: "40px",
              right: "40px",
              zIndex: 1,
              color: "#fff4e3",
            }}
          >
            <CloseIcon sx={{ fontWeight: "1000", fontSize: "40px" }} />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const { data: products } = useAllProducts(
    null,
    null,
    null,
    "desc",
    "popular",
    1,
    4,
    null,
    null,
    "",
    data ? data.material : null,
    null,
    data ? data.category_id.toString() : "",
    data ? data.subcategory_id?.toString() : ""
  );

  if (!data || !products) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress sx={{ color: color.accent }} />
      </Grid>
    );
  }

  const viewImages = [];
  if (data.main_image && data.preview_image && data.images) {
    viewImages.push(data.main_image, data.preview_image, ...data.images);
  }

  const handlePlus = () => {
    setAmount((prevAmount) => prevAmount + 1);
  };

  const handleMinus = () => {
    if (amount === 1) {
      return;
    } else {
      setAmount((prevAmount) => prevAmount - 1);
    }
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedValue(event.target.value);
  };

  

  // const handleAddToCart = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleAddToCart = () => {
    let newSingleCartProduct = {
      id: id,
      amount: amount,
      color: data.material === "silver" ? "white" : selectedValue,
      main_image: data.main_image,
      product_name: data.product_name,
      price: data.discount_flag ? data.discount_price : data.price,
   
    };
    const cartProducts = sessionStorage.getItem("products");
    if (selectedValue === "" && data.material === "gold") {
      enqueueSnackbar("Niste izabrali boju.", { variant: "error" });
    } else {
      if (!cartProducts) {
        // Not created yet
        sessionStorage.setItem(
          "products",
          JSON.stringify([newSingleCartProduct])
        );
        enqueueSnackbar("Proizvod dodat u korpu!", { variant: "default" });
      } else {
        let newCartProducts = [...JSON.parse(cartProducts)];
        const alreadyExists = newCartProducts.some(
          (productItem) => productItem.id === newSingleCartProduct.id
        );
        if (alreadyExists) {
          enqueueSnackbar("Proizvod već postoji u korpi", { variant: "info" });
        } else {
          newCartProducts.push(newSingleCartProduct);
          sessionStorage.setItem("products", JSON.stringify(newCartProducts));
          enqueueSnackbar("Proizvod dodat u korpu!", { variant: "default" });
        }
      }
    }
  };

  const handleBuyNow = () => {
    const productToBuy = {
      id: id,
      amount: amount,
      color: selectedValue,
      main_image: data.main_image,
      product_name: data.product_name,
      price: data.discount_flag ? data.discount_price : data.price,
    };
    if (selectedValue === "" && data.material === "gold") {
      enqueueSnackbar("Niste izabrali boju.", { variant: "error" });
    } else {
    sessionStorage.setItem("products", JSON.stringify([productToBuy]));
    navigate("/cart");
  }
}
    
  return (
    <Grid item>
      <ScrollToTop />
      {imageOpen ? renderDialog() : null}
      <Grid
        container
        direction="column"
        alignItems={phone ? "center" : "flex-start"}
        justifyContent="center"
        key={id}
      >
        <Grid item alignSelf="flex-start">
          <Typography className="current-path">
            Početna → {translateMaterial(data.material)} → {data.category_name}
            {data.subcategory_name !== null
              ? ` → ${data.subcategory_name}`
              : ""}
          </Typography>
        </Grid>
        <Grid item className="data-container">
          <Grid
            container
            direction={phone ? "column" : "row"}
            alignItems={phone ? "center" : "flex-start"}
            justifyContent={phone ? "center" : "flex-start"}
          >
            <Grid item className="image-switcher-container">
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <ImageSwitcher
                    images={viewImages}
                    handleDialogOpen={handleDialogOpen}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="info-container">
              <Grid
                container
                direction="column"
                alignItems={phone ? "center" : "flex-start"}
              >
                <Grid item>
                  <Typography className="single-product-title">
                    {data.product_name} - {translateMaterial(data.material)}{" "}
                    {data.briliant ? " sa brilijantima" : ""}
                    {auth ? (
                      <>
                        <Tooltip title="Idi na Admin Panel Ovog Proizvoda">
                          <IconButton
                            onClick={() => {
                              navigate(`/admin/products/${id}`);
                            }}
                          >
                            <SettingsSuggestIcon
                              sx={{ color: "#cd9c32" }}
                              className="admin-item-icon"
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : null}
                  </Typography>
                </Grid>
                {!phone ? (
                  <Grid item>
                    <Typography className="description">
                      {data.description}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className="price-container"
                  >
                    <Grid item>
                      <Typography
                        className={
                          data.discount_flag ? "discount-price" : "price"
                        }
                      >
                        {data.price} RSD
                      </Typography>
                    </Grid>
                    {data.discount_flag ? (
                      <Typography className="new-price">
                        {data.discount_price} RSD
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                {phone ? (
                  <Grid item>
                    <Typography className="description">
                      {data.description}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className="amount-container"
                  >
                    {!phone ? (
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          className="amount-change-container"
                        >
                          <Grid item>
                            <IconButton onClick={handleMinus}>
                              <Typography className="edit-amoun-icon">
                                -
                              </Typography>
                            </IconButton>
                          </Grid>
                          <Grid item className="amount">
                            <Typography>{amount}</Typography>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={handlePlus}>
                              <Typography className="edit-amoun-icon">
                                +
                              </Typography>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid item className="color-switcher-container">
                      <FormControl component="fieldset">
                      {data.material === "gold" ? (
                <RadioGroup
                  row
                  value={selectedValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    style={{ color: color.btn_dark }}
                    value="yellow"
                    control={<Radio style={{ color: color.btn_dark }} />}
                    label="Žuto zlato"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    style={{ color: color.btn_dark }}
                    value="pink"
                    control={<Radio style={{ color: color.btn_dark }} />}
                    label="Roze zlato"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    style={{ color: color.btn_dark }}
                    value="white"
                    control={<Radio style={{ color: color.btn_dark }} />}
                    label="Belo zlato"
                    labelPlacement="bottom"
                  />
                </RadioGroup>
                ):(
              
                  <RadioGroup
                  row
                  value="white"
                >
                  <FormControlLabel
                    style={{ color: color.btn_dark }}
                    value="white"
                    control={<Radio style={{ color: color.btn_dark }} />}
                    label="Bela"
                    labelPlacement="bottom"
                  />
                </RadioGroup>
                )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {!phone ? (
                  <Grid item className="buttons-container">
                    <Grid container direction="row" alignItems="center">
                      <Grid item className="basket-button-container">
                        <Button
                          className="basket-button"
                          onClick={handleAddToCart}
                        >
                          <Typography className="button-text">
                            Dodaj u korpu
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item className="buy-button-container">
                        <Button className="buy-button" onClick={handleBuyNow}>
                          <Typography className="button-text">Kupi</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {phone ? (
                  <>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Button
                            className="basket-button"
                            onClick={handleAddToCart}
                          >
                            <Typography className="button-text">
                              Dodaj u korpu
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            className="amount-change-container"
                          >
                            <Grid item>
                              <IconButton onClick={handleMinus}>
                                <Typography className="edit-amoun-icon">
                                  -
                                </Typography>
                              </IconButton>
                            </Grid>
                            <Grid item className="amount">
                              <Typography>{amount}</Typography>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={handlePlus}>
                                <Typography className="edit-amoun-icon">
                                  +
                                </Typography>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className="buy-button-container">
                      <Button className="buy-button" onClick={handleBuyNow}>
                        <Typography className="button-text">Kupi</Typography>
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="see-more">
          <Grid container direction="column">
            <Grid item>
              <Typography className="see-more-title">Pogledajte još</Typography>
            </Grid>
            <Grid item alignSelf="center">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {products.products.map((product) =>
                  product.id.toString() !== id ? (
                    <Product
                      key={product.id}
                      handleClick={() => handleProductClick(product.id)}
                      imageUrl={product.main_image_name}
                      previewUrl={product.preview_image_name}
                      name={product.product_name}
                      material={product.material}
                      price={product.price}
                      discount={product.discount_flag}
                      discountPrice={product.discount_price}
                    />
                  ) : null
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        className="attention-container"
      >
        <Grid item>
          <Typography className="attention-text">
            *Prilikom poručivanja nakita imajte u vidu da je za izradu potrebno
            7 do 10 radnih dana
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="attention-text">
            Hvala unapred na strpljenju!
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="attention-text">Jewerly Shop Doro</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ClientSingleProduct;
